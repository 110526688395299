import React from 'react';
import FlightPreview from '../FlightPreview';
import { ALL_CHATS, NO_FLIGHTS_CHECKEDIN } from '../../../utils/constants';
import { getUnreadMessagesCountByFlightId } from '../../../utils/helpers';
import { useGetMyFlightsCached } from '../../../utils/hooks/useGetMyFlightsCached';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../redux/reducers/authReducer';
import { selectChatMessages } from '../../../redux/reducers/chatReducer';
import { selectCheckedInUsers } from '../../../redux/reducers/checkinReducer';
import ListHeader from '../ChatHeader/ChatHeader';
import Title from '../../Title/Title';
import { selectUTC } from '../../../redux/reducers/settingsReducer';
import { calculateFlightNumber } from '../../../utils/calculateFlightNumber';

interface IAllChats {
  handleCloseChat: () => void;
  onChatSelect: (flight: string, flightNumber: string | null) => void;
}

const AllChats = ({ handleCloseChat, onChatSelect }: IAllChats) => {
  const userId = useSelector(selectUserId);
  const { data } = useGetMyFlightsCached(userId);
  const isUTC = useSelector(selectUTC);
  const chatMessages = useSelector(selectChatMessages);
  const checkedInFlights = data?.filter((flight) => flight.isCheckedIn);
  const checkedInRoles = useSelector(selectCheckedInUsers);
  const chats = checkedInFlights?.map((flight) => {
    const currentRole = checkedInRoles[flight.flightId]?.myRole;
    return {
      flightNumber: calculateFlightNumber(isUTC, flight.flightNumber),
      flightId: flight.flightId,
      messagesCount: getUnreadMessagesCountByFlightId(
        chatMessages,
        flight.flightId,
        currentRole?.technicalRole
      ),
      status: flight.flightStatus,
    };
  });

  const noCheckedinFlightsClassNames =
    'text-grey-60 text-24 font-body-bold h-full flex items-center justify-center dark:text-grey-25 p-24 ';

  const overlayTitleClass =
    'text-grey-40 font-semibold text-14 font-body-text dark:text-grey-12 ml-[5px] pt-[5px] mobile:ml-0 uppercase';

  return (
    <div className="bg-white dark:bg-grey-90 h-screen flex flex-col">
      <ListHeader handleClose={handleCloseChat}>
        <Title title={ALL_CHATS.MY_CHATS} titleColorClass={overlayTitleClass} />
      </ListHeader>
      <div className="flex-1 overflow-y-auto">
        {chats?.length ? (
          chats?.map((chat) => (
            <FlightPreview
              key={chat.flightNumber}
              title={chat.flightNumber}
              flightId={chat.flightId}
              messagesCount={chat.messagesCount}
              status={chat.status}
              flightNumber={chat.flightNumber}
              onFlightSelect={onChatSelect}
            />
          ))
        ) : (
          <div className={noCheckedinFlightsClassNames}>
            {NO_FLIGHTS_CHECKEDIN}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllChats;
