import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseStatus } from '../constants';
import {
  UserNotification,
  Notification,
  NotificationCategory,
  NotificationType,
} from '../../utils/generated/graphql';

export interface IUserNotification extends Omit<UserNotification, '_id'> {
  _id?: string;
}

interface INotificationGroups {
  [key: string]: IUserNotification[];
}

interface NewsfeedState {
  newsfeed: INotificationGroups;
  status: PromiseStatus;
  error: string | null;
  shouldFetchNotifications: boolean;
  sortingCriteria: 'departure-ASC' | 'departure-DSC' | 'time-ASC' | 'time-DSC';
  activeChatScreen: string | null;
  activeChatScreenFlightName: string | null;
  activeStationScreen: string | null;
  showSpecificNotificationsOnly: boolean;
}

const initialState = {
  newsfeed: {},
  status: PromiseStatus.IDLE,
  error: null,
  shouldFetchNotifications: true,
  sortingCriteria: 'time-ASC',
  activeChatScreen: null,
  activeChatScreenFlightName: null,
  activeStationScreen: null,
  showSpecificNotificationsOnly: false,
} as NewsfeedState;

export const newsfeedReducer = createSlice({
  name: 'newsfeed',
  initialState,
  reducers: {
    removeFlightNotifications(state, action) {
      const { newsfeed } = state;

      if (newsfeed && newsfeed[action.payload]) {
        delete newsfeed[action.payload];
      }
    },
    updateFlightNotificationsHistory(state) {
      state.shouldFetchNotifications = true;
    },
    addNotifications(state, action: PayloadAction<UserNotification[]>) {
      state.newsfeed = action.payload.reduce(
        (groups: INotificationGroups | {}, item: UserNotification) => {
          const delimiter = (() => {
            if (item.notification.type === NotificationType.FLIGHT) {
              return item?.notification?.details?.flightId;
            } else if (item.notification.type === NotificationType.STATION) {
              return item?.notification?.details?.station;
            } else if (item.notification.type === NotificationType.ROLE) {
              return item?.notification?.title;
            } else {
              return NotificationType.SYSTEM;
            }
          })();

          if (!delimiter) {
            return groups;
          }

          if (!groups[delimiter]) {
            groups[delimiter] = [];
          }

          if (item.notification.category === NotificationCategory.OFF_BLOCK) {
            groups[delimiter] = groups[delimiter].filter(
              (notification: UserNotification) =>
                notification.notification.category !==
                NotificationCategory.OFF_BLOCK
            );
          }

          groups[delimiter].push(item);
          groups[delimiter].sort(
            (a: UserNotification, b: UserNotification) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );

          return { ...groups };
        },
        {}
      );
      state.shouldFetchNotifications = false;
    },
    addNotification(
      state,
      action: PayloadAction<{
        userId: string;
        notification: Notification;
      }>
    ) {
      const targetItem = (() => {
        if (action.payload.notification.type === NotificationType.FLIGHT) {
          return action.payload.notification.details?.flightId;
        } else if (
          action.payload.notification.type === NotificationType.STATION
        ) {
          return action.payload.notification.details?.station;
        } else if (action.payload.notification.type === NotificationType.ROLE) {
          return action.payload.notification?.title;
        } else {
          return NotificationType.SYSTEM;
        }
      })();

      if (
        targetItem &&
        action.payload.notification?.category !== NotificationCategory.DEPARTED
      ) {
        let notificationsGroup =
          (state.newsfeed && state.newsfeed[targetItem]) ?? [];

        if (
          action.payload.notification.category ===
          NotificationCategory.OFF_BLOCK
        ) {
          notificationsGroup = notificationsGroup.filter(
            (notification) =>
              notification.notification.category !==
              NotificationCategory.OFF_BLOCK
          );
        }

        const existingNotification = notificationsGroup.find(
          (el) => el.notification._id === action.payload.notification._id
        );

        if (!!existingNotification) {
          existingNotification.notification = action.payload.notification;
        } else {
          notificationsGroup.unshift({
            notification: action.payload.notification,
            userId: action.payload.userId,
            read: false,
            createdAt: new Date().toISOString(),
            _id: action.payload.notification._id,
          });
        }

        state.newsfeed = {
          ...state.newsfeed,
          [targetItem]: notificationsGroup,
        };
      }
    },
    readFlightNotification(state, action) {
      state.newsfeed = {
        ...state.newsfeed,
        [action.payload]: state.newsfeed[action.payload].map(
          (notification) => ({ ...notification, read: true })
        ),
      };
    },
    setSortingCriteria(state, action) {
      state.sortingCriteria = action.payload;
    },
    setActiveFlightIdScreen(state, action) {
      state.activeChatScreen = action.payload;
    },
    setActiveFlightNumberScreen(state, action) {
      state.activeChatScreenFlightName = action.payload;
    },
    setStationScreen(state, action) {
      state.activeStationScreen = action.payload;
    },
    setShowSpecificNotificationsOnly(state, action) {
      state.showSpecificNotificationsOnly = action.payload;
    },
  },
});

export const selectAllNotifications = (state) => {
  return state.newsfeedReducer.newsfeed;
};
export const selectShouldFetchNotifications = (state) => {
  return state.newsfeedReducer.shouldFetchNotifications;
};
export const selectSortingCriteria = (state) => {
  return state.newsfeedReducer.sortingCriteria;
};

export const selectActiveChatScreen = (state) => {
  return state.newsfeedReducer.activeChatScreen;
};

export const selectActiveChatFlightNumber = (state) => {
  return state.newsfeedReducer.activeChatScreenFlightName;
};
export const selectStationScreen = (state) => {
  return state.newsfeedReducer.activeStationScreen;
};

export const selectShowSpecificNotificationsOnly = (state) => {
  return state.newsfeedReducer.showSpecificNotificationsOnly;
};

export const {
  removeFlightNotifications,
  updateFlightNotificationsHistory,
  addNotification,
  addNotifications,
  readFlightNotification,
  setSortingCriteria,
  setActiveFlightIdScreen,
  setStationScreen,
  setShowSpecificNotificationsOnly,
  setActiveFlightNumberScreen,
} = newsfeedReducer.actions;

export default newsfeedReducer.reducer;
