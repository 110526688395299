import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Title from '../Title/Title';
import { MultiSelect } from 'react-multi-select-component';
import './style.scss';
import Icon from '../Icon/Icon';
import { useSelector } from 'react-redux';
import { selectTheming } from '../../redux/reducers/settingsReducer';
import { THEME } from '../../utils/constants';
import { getAutomaticTheme } from '../../utils/hooks/useDarkMode';

interface IDropdownOption {
  value: string;
  label: string;
}

interface IMultiselectDropdown {
  options: IDropdownOption[];
  title: string;
  handleChange?: (string) => void;
  className?: string;
  disabled?: boolean;
  containerClassName?: string;
  titleClassName?: string;
  showDefaultEmpty?: boolean;
  selectionLimit?: number;
  defaultSelectedIndex?: number;
  isLoading?: boolean;
  disableSearch?: boolean;
  hasSelectAll?: boolean;
  clearInput?: boolean;
  inputClassName?: string;
}

const MultiselectDropdown = ({
  options,
  title,
  disabled,
  className,
  handleChange,
  containerClassName,
  titleClassName,
  selectionLimit,
  defaultSelectedIndex,
  isLoading = false,
  disableSearch = false,
  hasSelectAll = false,
  clearInput = false,
  inputClassName,
  ...others
}: IMultiselectDropdown) => {
  const containerClassNames = classNames('relative mt-8', {
    [containerClassName ?? '']: containerClassName,
  });
  const [selectedOptions, setSelectedOptions] = useState<IDropdownOption[]>([]);
  const theme = useSelector(selectTheming);
  const isDark =
    theme.toLowerCase() === THEME.DARK.toLowerCase() ||
    (theme.toLowerCase() === THEME.AUTOMATIC.toLowerCase() &&
      getAutomaticTheme().toLowerCase() === THEME.DARK.toLowerCase());
  const selectClassName = classNames({ dark: isDark });

  useEffect(() => {
    !isLoading &&
      defaultSelectedIndex !== undefined &&
      setSelectedOptions([options[defaultSelectedIndex]]);
  }, [isLoading]);

  useEffect(() => {
    if (clearInput) {
      setSelectedOptions([]);
    }
  }, [clearInput]);

  useEffect(() => {
    const options =
      selectionLimit && selectedOptions.length > selectionLimit
        ? selectedOptions.slice(selectionLimit)
        : selectedOptions;

    if (options) {
      if (options.length && handleChange) {
        handleChange(
          selectionLimit === 1
            ? options?.[0].label
            : options?.map((option) => option.label)
        );
      } else if (!options.length && handleChange) {
        handleChange(null);
      }
    }
  }, [selectedOptions]);

  return (
    <div id="multi-select-dropdown-notifications">
      <Title
        textClassName={inputClassName}
        title={title}
        {...others}
        titleColorClass={titleClassName}
        className={classNames({
          'pt-24 pb-8': !className,
          [className ?? ' ']: className,
        })}>
        <div className={containerClassNames}>
          <MultiSelect
            closeOnChangedValue={!!selectionLimit && selectedOptions.length > 0}
            disabled={disabled}
            options={options}
            value={
              selectionLimit && selectedOptions.length > selectionLimit
                ? selectedOptions.slice(selectionLimit)
                : selectedOptions
            }
            onChange={(optionArray: IDropdownOption[]) => {
              setSelectedOptions(optionArray);
            }}
            labelledBy="Select"
            hasSelectAll={hasSelectAll}
            disableSearch={disableSearch}
            valueRenderer={(selectedOptions, _options) => {
              return selectedOptions.length
                ? selectedOptions.map(
                    ({ label }, index) =>
                      label +
                      `${index === selectedOptions.length - 1 ? ` ` : `, `}`
                  )
                : ' ';
            }}
            ArrowRenderer={() => (
              <>
                <Icon
                  variant="arrowExpand"
                  className={classNames(
                    'fill-primary dark:fill-white order-1 mr-8',
                    { hidden: disabled }
                  )}
                />
              </>
            )}
            className={selectClassName}
          />
        </div>
      </Title>
    </div>
  );
};

export default MultiselectDropdown;
