import { gql } from '@apollo/client';
import {
  TIME_OBJECT,
  BEST_TIME,
  ERROR_FRAGMENT,
  MOST_PENALISING_REGULATION_CAUSE,
  ETD_DELAY_CODES,
} from '../fragments';

export const DETAILS_HEADER = gql`
  query DetailsHeader($flightInfo: DetailsHeaderInput!) {
    detailsHeader(flightInfo: $flightInfo) {
      data {
        flightId
        flightNumber {
          utc
          local
        }
        departureAirport
        arrivalAirport
        gate
        pos
        type
        registration
        bestTimeLogic {
          ...BestTime
        }
        flightStatus
        isFavourite
        isCheckedIn
        arrivalTimes {
          ...TimeObject
        }
        departureTimes {
          ...TimeObject
        }
        boardingNotReady
        newBoardingTime {
          ...TimeObject
        }
        mostPenalisingRegulationCause {
          ...PenalisingCause
        }
        etdDelay {
          ...EtdDelayCodes
        }
        actualOffBlockTime
        MCD
        showMCD
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TIME_OBJECT}
  ${BEST_TIME}
  ${MOST_PENALISING_REGULATION_CAUSE}
  ${ERROR_FRAGMENT}
  ${ETD_DELAY_CODES}
`;

export const AIRCRAFT_ROTATION = gql`
  query AircraftRotation($flightId: String!) {
    aircraftRotation(flightId: $flightId) {
      data {
        id
        flightNumber {
          utc
          local
        }
        departure
        arrival
        gate
        pos
        bestTimeLogic {
          arrivalTime
          arrivalTime12format
          arrivalString
          arrivalDateTime
        }
        departureTimes {
          ...TimeObject
        }
        arrivalTimes {
          ...TimeObject
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TIME_OBJECT}
  ${ERROR_FRAGMENT}
`;

export const PROCESS = gql`
  query Process($flightId: String!) {
    process(flightId: $flightId) {
      data {
        detailsHeader {
          flightId
          flightNumber {
            utc
            local
          }
          departureAirport
          arrivalAirport
          targetOffBlockTime {
            ...TimeObject
          }
          targetStartAprovalTime {
            ...TimeObject
          }
          calculatedTakeOffTime {
            ...TimeObject
          }
          gate
          pos
          type
          registration
          bestTimeLogic {
            ...BestTime
          }
          flightStatus
          isFavourite
          isCheckedIn
          role
          arrivalTimes {
            ...TimeObject
          }
          departureTimes {
            ...TimeObject
          }
          boardingNotReady
          newBoardingTime {
            ...TimeObject
          }
          MCD
          showMCD
          mostPenalisingRegulationCause {
            ...PenalisingCause
          }
          etdDelay {
            ...EtdDelayCodes
          }
          actualOffBlockTime
          scheduleDepartureTime {
            UTCTime
            localTime
          }
        }
        detailsGantt {
          id
          name
          targetStart {
            utcTimestamp
            utc
            utc12
            local
            local12
          }
          targetEnd {
            utcTimestamp
            utc
            utc12
            local
            local12
          }
          actualStart {
            utcTimestamp
            utc
            utc12
            local
            local12
          }
          actualEnd {
            utcTimestamp
            utc
            utc12
            local
            local12
          }
        }
        processes {
          acceptance
          fueling
          boarding
          ramp
          loadControl
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TIME_OBJECT}
  ${BEST_TIME}
  ${MOST_PENALISING_REGULATION_CAUSE}
  ${ERROR_FRAGMENT}
  ${ETD_DELAY_CODES}
`;

export const ABOVE_THE_WING = gql`
  query AboveTheWing($flightId: String!) {
    aboveTheWing(flightId: $flightId) {
      data {
        passengerSpecials {
          WCHR
          WCHC
          WCHS
          StandBy
          DEPA
          DEPU
          MEDA_MEQT
          STRC
          UMNR
        }
        passengerStatus {
          total
          boarded
          checkedIn
          booked
          available
          missing
          capacity
          jumps
        }
        passengerStatusByClass {
          firstClass {
            boarded
            accepted
            booked
            standBy
            available
            capacity
          }
          businessClass {
            boarded
            accepted
            booked
            standBy
            available
            capacity
          }
          premiumEconomyClass {
            boarded
            accepted
            booked
            standBy
            available
            capacity
          }
          economyClass {
            boarded
            accepted
            booked
            standBy
            available
            capacity
          }
          infants {
            boarded
            accepted
            booked
            standBy
          }
        }
        totalCounters {
          totalPassengers
          totalLuggage
        }
        handBaggages {
          cob
          hbag
          critical
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const INBOUND_CONNECTIONS = gql`
  query InboundConnections($flightId: String!) {
    inboundConnections(flightId: $flightId) {
      data {
        connections {
          id
          flightNumber {
            utc
            local
          }
          from
          gate
          position
          arrival
          checkedInPassengers
          checkedInPassengersStatus
          checkedInLuggage
          checkedInLuggageStatus
          arrivalTimeZoneObject {
            ...TimeObject
          }
        }
        inboundLuggage
        inboundPassengers
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TIME_OBJECT}
  ${ERROR_FRAGMENT}
`;

export const OUTBOUND_CONNECTIONS = gql`
  query OutboundConnections($flightId: String!) {
    outboundConnections(flightId: $flightId) {
      data {
        connections {
          id
          flightNumber {
            utc
            local
          }
          to
          gate
          position
          departure
          checkedInPassengers
          checkedInPassengersStatus
          checkedInLuggage
          checkedInLuggageStatus
          departureTimeZoneObject {
            ...TimeObject
          }
        }
        outboundLuggage
        outboundPassengers
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TIME_OBJECT}
  ${ERROR_FRAGMENT}
`;

export const BELOW_THE_WING = gql`
  query BelowTheWing($flightInfo: BelowTheWingInput!) {
    belowTheWing(flightInfo: $flightInfo) {
      data {
        flightId
        baggageStatus {
          loaded
          checkedIn
          offload
        }
        specialLoads {
          DGR
          AVIH
          WCH
          WEA
          STRL
          other
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const BAGGAGE_LIST = gql`
  query BaggageList($flightInfo: BaggageListInput!) {
    baggageList(flightInfo: $flightInfo) {
      data {
        status
        tagNumber
        ULD
        pos
        securityNumber
        category
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DETAILS_CREW = gql`
  query DetailsCrews($flightId: String!) {
    detailsCrew(flightId: $flightId) {
      data {
        deadheadCrewAssignments {
          totalCrewMembers
          assignedFlightId
          employeeRole {
            code
            roleDescription
          }
          assignmentType
          origin
          flightNumber {
            utc
            local
          }
          gate
          pos
          arrivalTime {
            ...BestTime
          }
        }
        onDutyCrewAssignments {
          totalCrewMembers
          assignedFlightId
          employeeRole {
            code
            roleDescription
          }
          assignmentType
          origin
          flightNumber {
            utc
            local
          }
          gate
          pos
          arrivalTime {
            ...BestTime
          }
        }
        cockpit
        cabin
        deadhead
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BEST_TIME}
  ${ERROR_FRAGMENT}
`;

export const BOARDING_REASONS = gql`
  query UpdateBoardingReasons($carrier: String) {
    updateBoardingReasons(carrier: $carrier) {
      carrier
      reasons {
        id
        reason
        single
      }
    }
  }
`;
