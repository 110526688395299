import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import QRCode from 'react-qr-code';
import Modal from '../Modal/Modal';
import { DEVICE_TYPE, YOU } from '../../utils/constants';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import { checkCUTEDevice } from '../../utils/helpers';

interface IPhoneNumber {
  number: string;
  showCallIcon?: boolean;
  isMe?;
  showQR?: boolean;
  setShowQR: () => void;
}
interface IPhoneItem {
  number: string;
  showPhone: boolean;
  containerClassName?: string;
  isMe?: boolean;
  showQR?: boolean;
  setShowQR: () => void;
}

const basePhoneClass =
  'font-head-light text-primary dark:text-grey-12 text-16 text-center py-4 px-[8px] rounded-8 border-primary dark:border-grey-12 border-1 border-opacity-25';

const YOU_FORMATTED = `(${YOU})`;

export const PhoneNumber = ({
  number,
  showCallIcon,
  isMe = false,
  showQR = false,
  setShowQR,
}: IPhoneNumber) => {
  const numberUri = `tel:${number}`;

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  const isDesktop = useDeviceBreakpoint(DEVICE_TYPE.DESKTOP);
  const isCUTEDevice = checkCUTEDevice();

  const [qrNumber, setQrNumber] = useState<String>();

  const callNumber = (showNumber: String) => {
    if (!isMobile && !showQR) {
      setQrNumber(showNumber);
      setShowQR();
    }
  };

  useEffect(() => {
    if (!showQR) setQrNumber('');
  });

  return (
    <>
      <div
        className="flex w-full cursor-pointer"
        onClick={() => callNumber(number)}>
        <a
          className={classNames('hidden mobile:flex', {
            'items-center w-full justify-between gap-x-[8px]': showCallIcon,
          })}
          href={numberUri}>
          {number}
          {isMe && <div className="ml-10">{YOU_FORMATTED}</div>}
        </a>
        <div className="mobile:hidden flex items-center">
          {number}
          {isMe && <div className="ml-10">{YOU_FORMATTED}</div>}
        </div>
        <Button
          className="mobile:hidden mr-0 ml-auto"
          Icon={<Icon variant="QR" className="dark:fill-white" />}
        />
      </div>
      {qrNumber && (
        <Modal
          title={''}
          showModal={showQR}
          setShowModal={setShowQR}
          showControls={false}>
          <div className="flex flex-col gap-y-6 items-center justify-center pb-[50px]">
            <QRCode value={numberUri} />
            {isDesktop && !isCUTEDevice ? (
              <a className={basePhoneClass} href={numberUri}>
                {qrNumber}
              </a>
            ) : (
              <p className={basePhoneClass}>{qrNumber}</p>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export const PhoneItem = ({
  number,
  showPhone,
  containerClassName,
  isMe,
  showQR = false,
  setShowQR,
}: IPhoneItem) => {
  const phoneClass = classNames(
    basePhoneClass,
    'flex w-full items-center justify-between mobile:justify-center',
    {
      hidden: !showPhone,
      block: showPhone,
    }
  );

  return (
    <div
      className={classNames(
        'flex flex-row-reverse mb-[3px]',
        containerClassName
      )}>
      <div className={phoneClass} data-testid="phone">
        <PhoneNumber
          number={number}
          isMe={isMe}
          showQR={showQR}
          setShowQR={setShowQR}
        />
      </div>
    </div>
  );
};
