import { gql } from '@apollo/client';

export const SEARCH_TELEPHONY = gql`
  query SearchTelephony($searchValue: String!) {
    searchTelephony(searchValue: $searchValue) {
      type
      stations {
        station
        roles {
          roleName
          users {
            phoneNumber
            externalUniqueID
            externalID
            group
          }
        }
      }
      checkinRoles {
        roles {
          name
          roleGroup
          checkedInUsers {
            userId
            phone
            technicalRole
          }
          canChat
          canCall
        }
        flightId

        myRole {
          roleGroup
          name
          technicalRole
          carrier
          permissions
          _id
        }
      }
    }
  }
`;
