import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import FlightDetailsHeader from '../../components/FlightDetailsHeader/FlightDetailsHeader';
import FlightDetail from '../../components/FlightDetail/FlightDetail';
import {
  AIRLINE_COLOR_MAP,
  BOARDING_TIME,
  EMPTY_STATE,
  ETD_SETTINGS,
  FLIGHT_SEARCH,
  GENERAL_NOTIFICATION_ARRAY,
  INITIAL_STATE,
  MY_FLIGHTS,
  NULL_TIME_STATE,
  TAGS,
  TAGS_COPY,
  URL_SOURCE,
  BRIEFING_PACKAGE,
  BAG_MERGER,
  DEVICE_TYPE,
} from '../../utils/constants';
import ReloadButton from '../../components/CustomButtons/ReloadButton/ReloadButton';
import Gantt from '../../components/Gantt/Gantt';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartureStationFromId } from '../../utils/flightUtil';
import { getAirlineKey, getThemeColor } from '../../utils/helpers';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useCountdown from '../../utils/hooks/useCountdown';
import {
  select24Format,
  selectRoles,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { useNotifications } from '../../utils/hooks/useNotifications';
import { getErrorMessage } from '../../utils/helpers';
import classNames from 'classnames';
import {
  selectIsMenuOpen,
  setIsCheckIn,
} from '../../redux/reducers/headerReducer';
import { calculateTime } from '../../utils/calculateTime';
import { useGetProcess } from '../../utils/hooks/useGetProcess';
import { useGetAircraftRotation } from '../../utils/hooks/useGetAircraftRotation';
import { useGetBelowTheWing } from '../../utils/hooks/useGetBelowTheWing';
import { useGetAboveTheWing } from '../../utils/hooks/useGetAboveTheWing';
import Button from '../../components/Button/Button';
import { Permission } from '../../utils/generated/graphql';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { selectCheckedInUsers } from '../../redux/reducers/checkinReducer';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import {
  OverlayModule,
  setOpenOverlay,
} from '../../redux/reducers/overlayReducer';
import { checkCUTEDevice } from '../../utils/helpers';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';
import { useShowBagMerger } from '../../utils/useShowBagMerger';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import { calculateFlightNumber } from '../../utils/calculateFlightNumber';

const processContainer = '-mt-[76px] px-24 pb-24';
const reloadButtonClass =
  ' mobile:left-0 w-fit absolute  top-24 mobile:top-[30px] z-40 left-[63px] right-0 mx-auto';

const Process = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isCheckedInState = useSelector(
    (state) => state.headerReducer.isCheckedIn
  );
  const isUTC = useSelector(selectUTC);

  const isCUTEDevice = checkCUTEDevice();

  const { flight } = useParams();

  const station = getDepartureStationFromId(flight);
  const isMenuOpen = useSelector(selectIsMenuOpen);
  const headerContainer = classNames('relative -translate-y-[76px]', {
    'z-30': !isMenuOpen,
  });

  const {
    data: dataHeader,
    loading: isFetchingHeader,
    loadingFirstTime: isLoadingHeader,
    error: errorEncounteredHeader,
    responseErrors: responseErrorsHeader,
    refetchProcess,
  } = useGetProcess(flight);

  const checkedInRoles = useSelector(selectCheckedInUsers);
  const currentRoleId = checkedInRoles[flight ?? '']?.myRole?._id;
  const roles = useSelector(selectRoles);

  const isOnline = useNetworkStatus();

  const hasBoardingUpdatePermission = useCheckPermissions(
    [Permission.UPDATE_BOARDING_TIME],
    CheckType.FULL,
    [currentRoleId]
  );

  const hasBriefingPackagePermission = useCheckPermissions(
    [Permission.BRIEFING_PACKAGE],
    CheckType.FULL,
    [currentRoleId]
  );

  const excludedStations =
    process?.env?.REACT_APP_TAC_ETD_STATIONS_EXCLUDED?.replace(' ', '').split(
      ','
    ) ?? [];

  const hasEtdUpdatePermission =
    useCheckPermissions(
      [Permission.UPDATE_ETD],
      CheckType.FULL,
      [currentRoleId],
      station
    ) && !excludedStations.includes(station);

  const hasAboveWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_ABOVE_WING_DATA],
    CheckType.FULL
  );

  const hasBelowWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_BELOW_WING_DATA],
    CheckType.FULL
  );

  const hasTimesDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_TIMES_DATA],
    CheckType.FULL
  );

  const hasStatusDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_STATUS_DATA],
    CheckType.FULL
  );

  const {
    data: dataAircraftRotation,
    isFetching: isFetchingAircraftRot,
    isLoading: isLoadingAircraftRot,
    error: errorEncounteredAircraftRot,
    responseErrors: responseErrorsAircraftRot,
    refetchAircraftRotation,
  } = useGetAircraftRotation(flight);

  const {
    data: dataBelowTheWing,
    loading: isFetchingBelowTheWing,
    loadingFirstTime: isLoadingBelowTheWing,
    error: errorEncounteredBelowTheWing,
    responseErrors: responseErrorsBelowTheWing,
    refetchBelowTheWing,
  } = useGetBelowTheWing(flight, station, !hasBelowWingDataPermission);

  const {
    data: dataAboveTheWing,
    loading: isFetchingAboveTheWing,
    loadingFirstTime: isLoadingAboveTheWing,
    error: errorEncounteredAboveTheWing,
    responseErrors: responseErrorsAboveTheWing,
    refetchAboveTheWing,
  } = useGetAboveTheWing(flight, !hasAboveWingDataPermission);

  const isLoading =
    isLoadingHeader ||
    isLoadingAircraftRot ||
    isLoadingAboveTheWing ||
    isLoadingBelowTheWing;

  const isFetching =
    isFetchingHeader ||
    isFetchingAircraftRot ||
    isFetchingAboveTheWing ||
    isFetchingBelowTheWing;

  const { baggageStatus, specialLoads } = dataBelowTheWing || {};

  const calculatedFlightNumber = calculateFlightNumber(
    isUTC,
    dataHeader?.detailsHeader?.flightNumber
  );
  const airlineKey = getAirlineKey(calculatedFlightNumber);

  const airlineColor = AIRLINE_COLOR_MAP[airlineKey];
  const displayNoDataState = errorEncounteredHeader || isLoading;
  const noDataState = errorEncounteredHeader ? EMPTY_STATE : INITIAL_STATE;
  const {
    departureAirport,
    arrivalAirport,
    gate,
    isFavourite,
    role,
    pos,
    registration,
    type,
    bestTimeLogic = { timeLeft: INITIAL_STATE },
    departureTimes = { bestTimeUTC: INITIAL_STATE },
    flightStatus = 'DEFAULT',
    isCheckedIn,
    MCD,
    showMCD,
  } = dataHeader?.detailsHeader || {};

  const {
    mostPenalisingRegulationCause,
    targetOffBlockTime,
    targetStartAprovalTime,
    calculatedTakeOffTime,
    bestTimeLogic: bestTimeLogicDetail = { timeLeft: INITIAL_STATE },
    departureTimes: departureTimesDetail = { bestTimeUTC: INITIAL_STATE },
  } = hasTimesDataPermission ? dataHeader?.detailsHeader || {} : {};

  const [isFlightFavorite, setIsFav] = useState(isFavourite);

  const { acceptance, boarding, fueling, loadControl, ramp } =
    hasStatusDataPermission ? dataHeader?.processes || {} : {};

  const { passengerStatus, passengerSpecials } = dataAboveTheWing || {};

  const passengerSpecialsComplete = {
    ...passengerSpecials,
    boardingNotReady: dataHeader?.detailsHeader?.boardingNotReady,
    newBoardingTime: dataHeader?.detailsHeader?.newBoardingTime,
  };

  const [errorsReceived, setErrorsReceived] = useState([]);
  const [allErrorsEncountered, setAllErrorsEncountered] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      let allErrorsReceived = [];
      let allErrorsEncountered = [];
      if (errorEncounteredAboveTheWing) {
        allErrorsEncountered.push(errorEncounteredAboveTheWing);
      }

      if (errorEncounteredAircraftRot) {
        allErrorsEncountered.push(errorEncounteredAircraftRot);
      }

      if (errorEncounteredBelowTheWing) {
        allErrorsEncountered.push(errorEncounteredBelowTheWing);
      }

      if (errorEncounteredHeader) {
        allErrorsEncountered.push(errorEncounteredHeader);
      }

      if (allErrorsEncountered.length > 1) {
        allErrorsEncountered = GENERAL_NOTIFICATION_ARRAY;
      }

      setAllErrorsEncountered(allErrorsEncountered);

      if (responseErrorsAboveTheWing) {
        allErrorsReceived.push(...responseErrorsAboveTheWing);
      }
      if (responseErrorsHeader) {
        allErrorsReceived.push(...responseErrorsHeader);
      }
      if (responseErrorsBelowTheWing) {
        allErrorsReceived.push(...responseErrorsBelowTheWing);
      }
      if (responseErrorsAircraftRot) {
        allErrorsReceived.push(...responseErrorsAircraftRot);
      }
      if (allErrorsReceived.length > 1) {
        allErrorsReceived = GENERAL_NOTIFICATION_ARRAY;
      }
      setErrorsReceived(allErrorsReceived);
    } else {
      setErrorsReceived([]);
    }
  }, [isFetching, errorEncounteredBelowTheWing, errorEncounteredAboveTheWing]);

  const [error, errorMessage] = getErrorMessage(
    errorsReceived,
    allErrorsEncountered
  );
  useNotifications(error ? { ...error } : null, errorMessage);

  const is24Format = useSelector(select24Format);

  const baggageTagArray = specialLoads
    ? Object.entries(specialLoads)
        .filter(([key, value]) => Number(value) !== 0 && key !== '__typename')
        .map((tag) => ({
          title: tag[0].toUpperCase() === 'WCH' ? 'Wheelchairs' : tag[0],
          isWarning: false,
        }))
    : [];

  const passengerTagArray = passengerSpecialsComplete
    ? Object.entries(passengerSpecialsComplete)
        .filter(
          ([key, value]) => key !== '__typename' && Number(value) !== 0 && value
        )
        .map(([tag, value]) => {
          if (tag === TAGS.BOARDING_NOT_READY) {
            return { title: TAGS_COPY.BOARDING_NOT_READY, isWarning: true };
          }
          if (tag === TAGS.NEW_BOARDING_TIME) {
            return {
              title: `${TAGS_COPY.NEW_BOARDING_TIME} ${calculateTime(
                isUTC,
                is24Format,
                value
              )}`,
              isWarning: true,
            };
          }
          return {
            title: tag,
            isWarning: false,
          };
        })
        .sort((a, b) => {
          if (a.isWarning && !b.isWarning) {
            return -1;
          } else if (!a.isWarning && b.isWarning) {
            return 1;
          } else {
            return 0;
          }
        })
    : [];

  const [ganttTimes, setGanttTimes] = useState([]);

  useEffect(() => {
    if (!!dataHeader && !isFetchingHeader) {
      setGanttTimes(dataHeader.detailsGantt?.data);
    }
  }, [dataHeader, isFetchingHeader]);

  const displayUnits = true;
  const timeLeft = useCountdown(
    isLoading ? INITIAL_STATE : bestTimeLogic?.timeLeft,
    displayUnits
  );

  const timeLeftTOBT = useCountdown(
    isLoading ? INITIAL_STATE : targetOffBlockTime?.UTCTime,
    displayUnits
  );

  const timeLeftTSAT = useCountdown(
    isLoading ? INITIAL_STATE : targetStartAprovalTime?.UTCTime,
    displayUnits
  );

  const timeLeftCTOT = useCountdown(
    isLoading ? INITIAL_STATE : calculatedTakeOffTime?.UTCTime,
    displayUnits
  );

  const themeColor = getThemeColor(calculatedFlightNumber);

  const tobtTime = isLoading
    ? INITIAL_STATE
    : calculateTime(isUTC, is24Format, targetOffBlockTime, NULL_TIME_STATE);
  const tsatTime = isLoading
    ? INITIAL_STATE
    : calculateTime(isUTC, is24Format, targetStartAprovalTime, NULL_TIME_STATE);
  const ctotTime = isLoading
    ? INITIAL_STATE
    : calculateTime(isUTC, is24Format, calculatedTakeOffTime, NULL_TIME_STATE);
  const time = isLoading
    ? INITIAL_STATE
    : calculateTime(isUTC, is24Format, departureTimesDetail);

  useEffect(() => {
    setIsFav(isFavourite);
    dispatch(setIsCheckIn(isCheckedIn));
  }, [dataHeader, isFetchingHeader]);

  useCustomHeader({
    headerTypeMobile: 'flight',
    departureAirport,
    arrivalAirport,
    type,
    headerTypeTablet: 'flight',
    flightNumber: calculatedFlightNumber,
    registration,
    bestTimeLogic,
    themeColor,
    departureTimes,
    isCheckedIn: isCheckedInState,
    MCD: MCD,
    showMCD: showMCD,
  });

  const getFieldStatus = (field) => {
    return isLoading ? INITIAL_STATE : field ?? EMPTY_STATE;
  };

  const showBagMerger = useShowBagMerger(currentRoleId, type, station ?? '');
  const hasMergeBagsPermission =
    FeatureFlagUtil.showFeature(
      process?.env?.REACT_APP_TAC_BAG_MERGER_FEATURE_DISABLED ?? '',
      roles
    ) && showBagMerger;

  const isBagMergerStatusesValid =
    getFieldStatus(ramp).toUpperCase() === 'OPEN' &&
    getFieldStatus(loadControl).toUpperCase() === 'CLOSED';

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  return (
    <>
      <div className={headerContainer}>
        <FlightDetailsHeader
          flightId={flight}
          flightStatus={flightStatus}
          flightNumber={calculatedFlightNumber}
          departureAirport={displayNoDataState ? noDataState : departureAirport}
          arrivalAirport={displayNoDataState ? noDataState : arrivalAirport}
          gate={displayNoDataState ? noDataState : gate}
          position={{
            value: displayNoDataState ? noDataState : pos,
          }}
          type={displayNoDataState ? noDataState : type}
          registration={displayNoDataState ? noDataState : registration}
          actualArrivalTime="6:15 am"
          previousLocation={
            pathname?.includes('favourites') ? MY_FLIGHTS : FLIGHT_SEARCH.HEADER
          }
          isFavourite={isFlightFavorite}
          aircraftRotation={
            displayNoDataState
              ? {
                  inbound: INITIAL_STATE,
                  departure: INITIAL_STATE,
                  arrival: INITIAL_STATE,
                  gate: INITIAL_STATE,
                  pos: INITIAL_STATE,
                  bestTimeLogic: {
                    arrivalString: INITIAL_STATE,
                  },
                  arrivalTimes: {
                    bestTimeUTC: INITIAL_STATE,
                    bestTimeUTC12format: INITIAL_STATE,
                    bestTimeLocal: INITIAL_STATE,
                    bestTimeLocal12format: INITIAL_STATE,
                  },
                }
              : dataAircraftRotation
          }
          checkedInRole={role}
          loading={isLoadingHeader}
          error={errorEncounteredHeader}
          station={station}
          isBagMergerEnabled={isBagMergerStatusesValid}
          MCD={MCD}
          showMCD={showMCD}
        />
      </div>
      <div className={processContainer}>
        <div className={reloadButtonClass}>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            isOnline && (
              <ReloadButton
                refetchFunctions={[
                  refetchProcess,
                  refetchAircraftRotation,
                  refetchBelowTheWing,
                  refetchAboveTheWing,
                ]}
              />
            )
          )}
        </div>

        {!isMobile && (
          <>
            <Button
              text={BOARDING_TIME.EDIT_CTA}
              onClick={() =>
                dispatch(setOpenOverlay(OverlayModule.BOARDING_TIME_UPDATE))
              }
              className={classNames(
                `px-24 rounded-4 w-full py-12 mt-38 hidden mobile:block ${airlineColor} dark:bg-white`,
                {
                  '!hidden': !hasBoardingUpdatePermission,
                }
              )}
              textClassName="font-head-bold text-white dark:text-primary text-14"
            />
            <Button
              text={ETD_SETTINGS.EDIT_CTA}
              onClick={() =>
                dispatch(setOpenOverlay(OverlayModule.ETD_SETTINGS))
              }
              className={classNames(
                `px-24 rounded-4 w-full py-12 mt-38 hidden mobile:block ${airlineColor} dark:bg-white`,
                {
                  '!hidden': !hasEtdUpdatePermission,
                  'mt-[10px]': hasBoardingUpdatePermission,
                }
              )}
              textClassName="font-head-bold text-white dark:text-primary text-14"
            />
            <Button
              text={BRIEFING_PACKAGE.TITLE}
              onClick={() =>
                dispatch(setOpenOverlay(OverlayModule.BRIEFING_PACKAGE))
              }
              className={classNames(
                `px-24 rounded-4 w-full py-12 mt-38 hidden mobile:block ${airlineColor} dark:bg-white`,
                {
                  '!hidden': !hasBriefingPackagePermission || isCUTEDevice,
                  'mt-[10px]':
                    hasEtdUpdatePermission || hasBoardingUpdatePermission,
                }
              )}
              textClassName="font-head-bold text-white dark:text-primary text-14"
            />
            <Button
              text={BAG_MERGER.TITLE}
              onClick={() => dispatch(setOpenOverlay(OverlayModule.MERGE_BAGS))}
              className={classNames(
                `px-24 rounded-4 w-full py-12 mt-38 hidden mobile:block ${airlineColor} dark:bg-white`,
                {
                  '!opacity-50': !isBagMergerStatusesValid,
                  '!hidden': !hasMergeBagsPermission,
                  'mt-[10px]':
                    hasEtdUpdatePermission ||
                    hasBoardingUpdatePermission ||
                    hasBriefingPackagePermission,
                }
              )}
              textClassName="font-head-bold text-white dark:text-primary text-14"
            />
          </>
        )}

        <FlightDetail
          flightData={{
            flightStatus: flightStatus || 'DEFAULT',
            acceptance: getFieldStatus(acceptance),
            fueling: getFieldStatus(fueling),
            boarding: getFieldStatus(boarding),
            ramp: getFieldStatus(ramp),
            loadControl: getFieldStatus(loadControl),
            departureTime: {
              time: time,
              delay: bestTimeLogicDetail?.delay
                ? `+${bestTimeLogicDetail?.delay}m`
                : null,
            },
            countDown: ['STD', 'ETD'].includes(
              bestTimeLogicDetail?.timeLogicUsed
            )
              ? timeLeft
              : undefined,
            timeLogicUsed: bestTimeLogicDetail?.timeLogicUsed,
            targetOffBlockTime: { time: tobtTime, countDown: timeLeftTOBT },
            targetStartAprovalTime: { time: tsatTime, countDown: timeLeftTSAT },
            calculatedTakeOffTime: { time: ctotTime, countDown: timeLeftCTOT },
            mostPenalisingRegulationCause,
          }}
          baggageData={{
            value: getFieldStatus(baggageStatus?.loaded),
            max: getFieldStatus(baggageStatus?.checkedIn),
            tagArray: hasBelowWingDataPermission ? baggageTagArray : [],
          }}
          passengerData={{
            value: getFieldStatus(passengerStatus?.boarded),
            max: getFieldStatus(passengerStatus?.checkedIn),
            tagArray: hasAboveWingDataPermission ? passengerTagArray : [],
          }}
          flightId={flight}
          redirectSource={
            pathname?.includes(URL_SOURCE.FAVOURITES)
              ? URL_SOURCE.FAVOURITES
              : URL_SOURCE.SEARCH
          }
          isLoading={isLoading}
          processList={dataHeader?.detailsGantt}
        />
        <div className="mt-32 mb-44 bg-white1 dark:bg-black1 rounded-8">
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_GANTT_FEATURE_DISABLED ?? '',
            roles
          ) &&
            ganttTimes.length > 0 && <Gantt times={ganttTimes} />}
        </div>
      </div>
    </>
  );
};

export default Process;
