import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListHeader from '../Chat/ChatHeader/ChatHeader';
import {
  DEVICE_TYPE,
  MY_CHECKIN_FLIGHTS,
  NO_CHECKED_IN_FLIGHT,
  NO_TELEPHONY_STATION,
  TELEPHONE_BOOKS,
  TELEPHONY_OVERLAY,
} from '../../utils/constants';
import { selectUserId } from '../../redux/reducers/authReducer';
import { useGetMyFlightsCached } from '../../utils/hooks/useGetMyFlightsCached';
import FlightPreview from '../Chat/FlightPreview';
import TeamContact from '../TeamContact/TeamContact';
import {
  selectActiveChatScreen,
  setActiveFlightIdScreen,
  setStationScreen,
  selectStationScreen,
} from '../../redux/reducers/newsfeedReducer';
import classNames from 'classnames';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import { TelephonySearch } from './TelephonySearch';
import StationPreview from '../StationContact/StationPreview';
import StationContact from '../StationContact/StationContact';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';
import {
  CacheTelephonyEntry,
  Permission,
  TelephonySearchType,
} from '../../utils/generated/graphql';
import { selectTelephonyStation } from '../../redux/reducers/telephoneBookReducer';
import { useSearchTelephonyLazy } from '../../utils/hooks/useGetTelephonySearch';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import {
  selectStationsList,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import Title from '../Title/Title';
import { calculateFlightNumber } from '../../utils/calculateFlightNumber';

interface ITelephonyContainer {
  handleCloseTelephony: () => void;
}
const containerClass = 'grid grid-cols-2 mobile:grid-cols-1 h-full';
const headerClassName =
  'text-grey-40 text-12 font-body-text dark:text-grey-12 ml-8 mobile:ml-0';

const overlayTitleClass =
  'text-grey-40 font-semibold text-14 font-body-text dark:text-grey-12 ml-8 pt-[7px] mobile:ml-0';

const TelephonyContainer = ({ handleCloseTelephony }: ITelephonyContainer) => {
  const isOffline = !useNetworkStatus();

  const dispatch = useDispatch();
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  const activeTelephonyScreen = useSelector(selectActiveChatScreen);
  const stationScreen = useSelector(selectStationScreen);
  const userId = useSelector(selectUserId);
  const isUTC = useSelector(selectUTC);
  const { data } = useGetMyFlightsCached(userId);
  const checkedInFlights = data?.filter((flight) => flight.isCheckedIn);

  const hasGeneralSectionAccess = useCheckPermissions([
    Permission.TELEPHONY_PHONEBOOK_GENERAL,
  ]);

  const hasStationAccess = useCheckPermissions([
    Permission.TELEPHONY_PHONEBOOK_STATION,
  ]);

  const flights = checkedInFlights?.map((flight) => {
    const flightNumber = calculateFlightNumber(isUTC, flight.flightNumber);
    return {
      flightNumber,
      flightId: flight.flightId,
      status: flight.flightStatus,
    };
  });
  const firstFlight = flights?.[0];

  const stationsList = useSelector(selectTelephonyStation);
  const { loading: loadingSearch, getSearchValue } = useSearchTelephonyLazy();
  const userSettingsStations = useSelector(selectStationsList);
  const stationsAccess = hasStationAccess ? userSettingsStations : [];
  const telephonyStationsList = hasGeneralSectionAccess
    ? ['General', ...stationsAccess]
    : [...stationsAccess];
  const firstStation = telephonyStationsList[0];
  const [selectedStation, setSelectedStation] = useState<string | null>(null);
  const [selectedFlightDetails, setSelectedFlightDetails] = useState<{
    flightId: string | null;
    flightNumber: string | null;
  } | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [currentStationInfo, setCurrentStationInfo] =
    useState<CacheTelephonyEntry | null>(stationsList[firstStation]);
  const upperSearchValue = searchValue.toUpperCase();

  const handleFlightSelect = (
    flightId: string | null,
    flightNumber: string | null
  ) => {
    setShowEmptyMessage(false);
    setSelectedFlightDetails({
      flightId,
      flightNumber,
    });
    setSelectedStation(null);
    setCurrentStationInfo(null);
    isMobile && dispatch(setActiveFlightIdScreen(flightId));
  };

  const handleStationSelect = (
    stationName: string | null,
    stationInfo?: CacheTelephonyEntry | null
  ) => {
    setShowEmptyMessage(false);
    setSelectedStation(stationName);
    stationName && setCurrentStationInfo(stationsList[stationName]);
    stationInfo && setCurrentStationInfo(stationInfo);
    setSelectedFlightDetails({ flightId: null, flightNumber: null });
    isMobile && dispatch(setStationScreen(stationName));
  };

  const handleOnClick = async () => {
    const stations = Object.keys(stationsList)?.filter(
      (value) => value === upperSearchValue
    );

    if (stations.includes(upperSearchValue)) {
      handleStationSelect(
        stationsList?.[upperSearchValue]?.station ?? '',
        stationsList?.[upperSearchValue]
      );
      return;
    }
    if (!isOffline) {
      const responseSearch = await getSearchValue(upperSearchValue);
      const type = responseSearch?.type;
      if (
        type === TelephonySearchType.FLIGHT &&
        responseSearch?.checkinRoles?.[0]
      ) {
        const calculatedFlightNumber = calculateFlightNumber(
          isUTC,
          responseSearch?.checkinRoles?.[0]?.flightNumber ?? {}
        );
        handleFlightSelect(
          responseSearch?.checkinRoles?.[0]?.flightId ?? '',
          calculatedFlightNumber
        );
      } else if (
        type === TelephonySearchType.STATION &&
        responseSearch?.stations?.[0]
      ) {
        handleStationSelect(
          responseSearch?.stations?.[0]?.station ?? '',
          responseSearch?.stations?.[0]
        );
      } else {
        setEmptyMessage();
      }
    }
  };
  const setEmptyMessage = async () => {
    isMobile && dispatch(setActiveFlightIdScreen(' '));
    setSelectedFlightDetails({ flightId: ' ', flightNumber: ' ' });
    setSelectedStation(' ');
    setCurrentStationInfo(null);
    setShowEmptyMessage(true);
  };

  const hasSearchAccess = useCheckPermissions([Permission.TELEPHONY_SEARCH]);

  return (
    <div className="bg-white dark:bg-grey-90 h-dvh flex flex-col">
      <div className={containerClass}>
        <div
          className={classNames(
            'relative flex flex-col h-dvh border-r-1 mobile:border-r-0',
            {
              'mobile:hidden': activeTelephonyScreen || stationScreen,
            }
          )}>
          <ListHeader handleClose={isMobile ? handleCloseTelephony : undefined}>
            <Title
              title={TELEPHONY_OVERLAY}
              titleColorClass={overlayTitleClass}
            />
          </ListHeader>
          <div className="flex-1 overflow-y-auto">
            <ListHeader>
              <div className={headerClassName}>{MY_CHECKIN_FLIGHTS}</div>
            </ListHeader>
            <div className="flex-1 overflow-y-auto">
              {flights?.length ? (
                flights?.map((flight) => (
                  <FlightPreview
                    key={flight.flightNumber}
                    title={flight.flightNumber}
                    flightId={flight.flightId}
                    status={flight.status}
                    onFlightSelect={() =>
                      handleFlightSelect(flight.flightId, flight.flightNumber)
                    }
                    isOnTelephony
                    selected={
                      !selectedStation &&
                      flight.flightId ===
                        (selectedFlightDetails?.flightId ??
                          firstFlight?.flightId)
                    }
                  />
                ))
              ) : (
                <Title
                  title={NO_CHECKED_IN_FLIGHT}
                  titleColorClass="flex justify-center text-primary text-14 font-body-text dark:text-grey-12"
                  className="h-[66px] p-24 bg-white dark:bg-grey-90 border-b-1 border-b-grey-12 dark:border-b-grey-60"></Title>
              )}
            </div>

            {(hasStationAccess || hasGeneralSectionAccess) && (
              <>
                <ListHeader>
                  <div className={headerClassName}>{TELEPHONE_BOOKS}</div>
                </ListHeader>
                <div className="flex-1 overflow-y-auto">
                  {telephonyStationsList.length ? (
                    telephonyStationsList.map((station) => (
                      <StationPreview
                        key={station ?? ''}
                        stationName={station ?? ''}
                        onStationSelect={() =>
                          handleStationSelect(station ?? '')
                        }
                        selected={
                          !selectedFlightDetails?.flightId &&
                          station ===
                            (selectedStation ??
                              (!checkedInFlights?.length ? firstStation : null))
                        }
                      />
                    ))
                  ) : (
                    <Title
                      title={NO_TELEPHONY_STATION}
                      titleColorClass="flex justify-center text-primary text-14 font-body-text dark:text-grey-12"
                      className="h-[66px] p-24 bg-white dark:bg-grey-90 border-b-1 border-b-grey-12 dark:border-b-grey-60"></Title>
                  )}
                </div>
              </>
            )}
          </div>
          {hasSearchAccess && (
            <TelephonySearch
              searchValue={searchValue}
              onInputChange={setSearchValue}
              onSearchButtonClick={handleOnClick}
              loading={loadingSearch}
            />
          )}
        </div>
        {selectedFlightDetails?.flightId ||
        (checkedInFlights?.length && !selectedStation) ? (
          <div
            className={classNames({
              'flex mobile:hidden border-r-0': !activeTelephonyScreen,
            })}>
            <TeamContact
              flightId={
                selectedFlightDetails?.flightId ?? firstFlight?.flightId
              }
              title={
                selectedFlightDetails?.flightId ||
                firstFlight ||
                showEmptyMessage
                  ? selectedFlightDetails?.flightNumber ??
                    firstFlight?.flightNumber ??
                    ''
                  : ''
              }
              handleClose={handleCloseTelephony}
              handleBack={() => handleFlightSelect(null, null)}
              isOnTelephony
              showEmptyMessage={showEmptyMessage}
            />
          </div>
        ) : telephonyStationsList.length && !selectedFlightDetails?.flightId ? (
          <div
            className={classNames('h-screen mobile:h-dvh overflow-y-hidden', {
              'flex mobile:hidden border-r-0': !stationScreen,
            })}>
            <StationContact
              key={currentStationInfo?.station ?? firstStation}
              stationInfo={currentStationInfo ?? stationsList[firstStation]}
              handleClose={handleCloseTelephony}
              handleBack={() => handleStationSelect(null)}
              showEmptyMessage={showEmptyMessage}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TelephonyContainer;
