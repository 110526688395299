import { useState, useEffect } from 'react';
import { NO_DEFAULT_FLIGHTS } from '../constants';

export const useFlightsPagination = (
  flightsList,
  resetToDefault,
  setResetToDefault,
  isLoading
) => {
  const [paginationState, setPaginationState] = useState({
    startIndex: null,
    visibleFlights: [],
    totalClicks: 0,
    initialState: false,
  });

  useEffect(() => {
    if (isLoading) {
      setPaginationState((prevState) => ({
        ...prevState,
        startIndex: null,
        visibleFlights: [],
        initialState: false,
        totalClicks: 0,
      }));
      return;
    }

    const getVisibleFlights = () => {
      const start =
        paginationState.startIndex > 0 ? paginationState.startIndex : 0;
      return flightsList.slice(start, flightsList.length);
    };

    if (!paginationState.initialState && flightsList.length) {
      let startIndex, endIndex;

      const findFirstNextFlight = flightsList.findIndex(
        (flight) => flight.closestDeparture
      );

      if (findFirstNextFlight >= 0) {
        startIndex = findFirstNextFlight;
        setResetToDefault(false);
      } else {
        startIndex = flightsList.length;
      }
      setPaginationState((prevState) => ({
        ...prevState,
        initialState: true,
        startIndex,
      }));
    }
    setPaginationState((prevState) => ({
      ...prevState,
      visibleFlights: getVisibleFlights(),
    }));
  }, [
    resetToDefault,
    isLoading,
    setResetToDefault,
    paginationState.startIndex,
    paginationState.initialState,
    flightsList,
  ]);

  const maxNoClicks = paginationState.totalClicks < 5;
  const displayEarlierBtn = paginationState.startIndex > 0 && maxNoClicks;

  return {
    visibleFlights: paginationState.visibleFlights.length
      ? [{}, ...paginationState.visibleFlights]
      : [],
    setEarlierFlights: () =>
      setPaginationState((prevState) => ({
        ...prevState,
        startIndex: prevState.startIndex - NO_DEFAULT_FLIGHTS,
        totalClicks: prevState.totalClicks + 1,
      })),
    displayEarlierBtn,
  };
};
