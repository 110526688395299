import React, { useRef, useState } from 'react';
import { MOBILE_BREAKPOINT } from '../../../utils/constants';
import {
  Notification,
  NotificationType,
  Permission,
} from '../../../utils/generated/graphql';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import { NotificationModalVariant } from '../../../utils/notifications/notificationModalUtil';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import Title from '../../Title/Title';
import { IModalData } from '../AdminNotificationCenter';
import useCheckPermissions from '../../../utils/hooks/useCheckPermissions';
import { useSelector } from 'react-redux';
import { selectStationsList } from '../../../redux/reducers/settingsReducer';
import classNames from 'classnames';

interface INotificationHistoryItem {
  notification: Notification;
  setModalData: React.Dispatch<React.SetStateAction<IModalData | null>>;
}
const NotificationHistoryItem = ({
  notification,
  setModalData,
}: INotificationHistoryItem) => {
  const isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
  const stationsList: string[] = useSelector(selectStationsList);
  const [showDescription, setShowDescription] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const clickOutsideRef = useRef(null);

  useClickOutside(clickOutsideRef, () => setShowDropdown(false), showDropdown);

  const deleteDate = notification?.details?.schedule?.deleteDate
    ? new Date(notification.details.schedule.deleteDate).toLocaleString(
        'en-UK',
        {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: 'numeric',
        }
      )
    : '';
  const sendDate = notification?.details?.schedule?.sendDate
    ? new Date(notification.details.schedule.sendDate).toLocaleString('en-UK', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: 'numeric',
      })
    : '';

  const hasDeleteAllPermissions = useCheckPermissions([
    Permission.DELETE_ALL_NOTIFICATIONS,
  ]);
  const hasDeleteOwnStationPermissions = useCheckPermissions([
    Permission.DELETE_STATION_NOTIFICATIONS,
  ]);
  const hasDeleteRolePermissions = useCheckPermissions([
    Permission.DELETE_ROLE_NOTIFICATIONS,
  ]);

  const hasUpdateAllPermissions = useCheckPermissions([
    Permission.UPDATE_ALL_NOTIFICATIONS,
  ]);
  const hasUpdateOwnStationPermissions = useCheckPermissions([
    Permission.UPDATE_STATION_NOTIFICATIONS,
  ]);

  const isOwnStationNotification = stationsList.includes(
    notification.details.station ?? ''
  );

  const isRoleNotification = notification.type === NotificationType.ROLE;

  const canDeleteCurrentNotification =
    (isOwnStationNotification && hasDeleteOwnStationPermissions) ||
    (hasDeleteRolePermissions && isRoleNotification);

  const canUpdateCurrentNotification =
    isOwnStationNotification && hasUpdateOwnStationPermissions;

  const showDeleteButton =
    hasDeleteAllPermissions || canDeleteCurrentNotification;
  const showUpdateButton =
    !isRoleNotification &&
    (hasUpdateAllPermissions || canUpdateCurrentNotification);

  if (isMobile) {
    const notificationOperations: string[] = [];

    if (showDeleteButton) {
      notificationOperations.push('Delete');
    }
    if (showUpdateButton) {
      notificationOperations.push('Edit');
    }

    return (
      <div className="flex flex-col border-b-1 border-b-grey-12 last:border-b-0 mb-[20px] last:mb-0">
        <section className="flex justify-between">
          <Title title="Type">
            <p className="w-[65px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
              {notification.type}
            </p>
          </Title>
          <Title title="Station">
            <p className="w-[65px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
              {notification.details.station}
            </p>
          </Title>
          <div className="flex items-start gap-x-2 relative">
            <Button
              disabled={!showDeleteButton && !showUpdateButton}
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown((prev) => !prev);
              }}
              Icon={
                <Icon
                  variant="more"
                  className="dark:fill-white"
                  width={18}
                  height={18}
                />
              }
            />
            {showDropdown && (
              <div className="origin-bottom-right absolute right-[1px] px-12 top-10 mt-2 rounded-8 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 dark:bg-grey-90 dark:ring-white dark:ring-opacity-10">
                <div className="py-1 flex flex-col">
                  {notificationOperations.map((option) => (
                    <Button
                      key={option}
                      className="flex items-center gap-x-2 border-b-1 border-grey-12 py-2 last:border-b-0 "
                      onClick={() => {
                        option === 'Edit'
                          ? setModalData({
                              variant: NotificationModalVariant.EDIT,
                              notificationId: notification._id,
                            })
                          : setModalData({
                              variant: NotificationModalVariant.DELETE,
                              notificationId: notification._id,
                            });
                      }}
                      text={option}
                      Icon={
                        <Icon
                          variant={option === 'Edit' ? 'pen' : 'deleteIcon'}
                          className="dark:fill-white"
                        />
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="flex justify-between">
          <Title title="Start Date">
            <p className="w-[110px] text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
              {sendDate}
            </p>
          </Title>
          <Title title="End Date">
            <p className="w-[110px] text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
              {deleteDate}
            </p>
          </Title>
          <div className="w-[18px] h-[44px]"></div>
        </section>
        <section className="flex justify-between">
          <Title title="Subject">
            <p className="w-[200px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
              {notification.subtitle}
            </p>
          </Title>
          <Button
            ref={clickOutsideRef}
            onClick={() => setShowDescription((prev) => !prev)}
            Icon={
              <Icon
                variant={showDescription ? 'arrowCollapse' : 'arrowExpand'}
                className="fill-primary dark:fill-white"
              />
            }
          />
        </section>
        {showDescription && (
          <div className="flex items-center">
            <span>{notification.description}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex justify-between py-4 flex-col pr-24 border-b-1 border-b-grey-12 last:border-b-0">
      <div className="flex justify-between">
        <div className="flex flex-row gap-x-[10px]">
          <p className="w-[200px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
            {notification.subtitle}
          </p>
          <p className="w-[65px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
            {notification.details.createdBy}
          </p>
          <p className="w-[65px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
            {notification.type}
          </p>
          <p className="w-[65px] truncate text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
            {notification.details.station}
          </p>
        </div>
        <div className="flex gap-x-[10px]">
          <p className="w-[110px] text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
            {sendDate}
          </p>
          <p className="w-[110px] text-primary font-14 font-body-bold leading-8 dark:text-grey-12">
            {deleteDate}
          </p>

          <Button
            disabled={!showUpdateButton}
            className={classNames({ invisible: !showUpdateButton })}
            onClick={() =>
              setModalData({
                variant: NotificationModalVariant.EDIT,
                notificationId: notification._id,
              })
            }
            Icon={<Icon variant="pen" className="dark:fill-white" />}
          />

          <Button
            disabled={!showDeleteButton}
            className={classNames({ invisible: !showDeleteButton })}
            onClick={() =>
              setModalData({
                variant: NotificationModalVariant.DELETE,
                notificationId: notification._id,
              })
            }
            Icon={<Icon variant="deleteIcon" className="dark:fill-white" />}
          />

          <Button
            onClick={() => setShowDescription((prev) => !prev)}
            Icon={
              <Icon
                variant={showDescription ? 'arrowCollapse' : 'arrowExpand'}
                className="fill-primary dark:fill-white"
              />
            }
          />
        </div>
      </div>
      {showDescription && (
        <div className="flex items-center">
          <span>{notification.description}</span>
        </div>
      )}
    </div>
  );
};

export default NotificationHistoryItem;
