import React from 'react';
import Icon from '../components/Icon/Icon';

interface IBadgeConfig {
  [key: string]: {
    Icon: JSX.Element;
    className?: string;
    text?: string;
    textClassName?: string;
    width?: string;
  };
}

export const ERROR_BADGE = (errorText: string) => ({
  className: 'bg-red w-86',
  text: errorText,
  textClassName: 'font-body-text text-white',
});

export const BADGE_CONFIG: IBadgeConfig = {
  DEPARTED: {
    Icon: (
      <Icon width={16} height={16} variant="takeoff" className="fill-white" />
    ),
    width: 'w-[86px]',
    className: 'bg-green w-86',
    text: 'Departed',
    textClassName: 'font-body-text text-white',
  },
  DEPARTED_NO_TEXT: {
    Icon: (
      <Icon width={20} height={24} variant="takeoff" className="fill-white" />
    ),
    className: 'justify-center bg-green',
    width: 'w-[32px]',
  },
  CANCELLED: {
    Icon: (
      <div className="relative flex justify-center items-center w-[24px] h-[24px]">
        <div className="bg-white rounded-full w-[14px] h-[20px]"></div>
        <Icon
          width={24}
          height={24}
          variant="cancel"
          className="fill-red absolute"
        />
      </div>
    ),
    width: 'w-[86px]',
    className: 'bg-red w-86',
    text: 'Cancelled',
    textClassName: 'font-body-text text-white pr-[4px]',
  },
  CANCELLED_NO_TEXT: {
    Icon: (
      <>
        <div className="bg-white rounded-full w-[22px] h-[22px]"></div>
        <Icon
          width={26}
          height={26}
          variant="cancel"
          className="fill-red absolute"
        />
      </>
    ),
    className: 'relative justify-center bg-red',
    width: 'w-[32px]',
  },
};
