import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import PulseNotificationIcon from '../PulseNotificationIcon/PulseNotificationIcon';
import classNames from 'classnames';
import { EMPTY_STATE } from '../../utils/constants';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';
import { reloadRef } from '../CustomButtons/ReloadButton/ReloadButton.tsx';

const flightNumberClassNames =
  'font-head-bold text-white text-24 leading-[26px]';
const textClassNames = 'font-body-thin text-white text-18 leading-[17px]';
const extraDetailsClassNames =
  'self-end text-12 text-white font-body-text leading-[12px]';
const timeClassNames = 'font-body-thin text-white  text-30 leading-[33px]';
const smallContainerClassNames = 'flex gap-32 items-center';
const routeClassNames = 'flex items-center mobile:hidden';
const routeAirportClassNames = 'font-head-light text-18 text-white';

interface ITimeObject {
  value: string;
  extraDetails: null | string;
}

interface IFlightDetailsHeaderStickyProps {
  flightNumber: string;
  type: string;
  departureAirport: string;
  arrivalAirport: string;
  registration: string;
  time1: ITimeObject;
  time2: string | null;
  themeColor: string | null;
  isCheckedIn?: boolean;
  MCD?: string;
  showMCD?: boolean;
}

const FlightDetailsHeaderSticky = ({
  flightNumber,
  type = EMPTY_STATE,
  departureAirport,
  arrivalAirport,
  registration = EMPTY_STATE,
  time1,
  time2,
  themeColor,
  isCheckedIn,
  MCD,
  showMCD,
  ...others
}: IFlightDetailsHeaderStickyProps) => {
  const containerClassNames =
    'flex justify-between h-[76px] p-24 dark:bg-grey-90 dark:border-2 dark:border-black';

  const mcdRef = useRef<HTMLDivElement>(null);
  const registrationRef = useRef<HTMLDivElement>(null);
  const [marginLeft, setMarginLeft] = useState<number>(0);

  const GAP_BETWEEN_FIELDS = 32;

  function repositionMCD() {
    if (mcdRef.current && reloadRef.current && registrationRef.current) {
      const mcdPosition = mcdRef.current.getBoundingClientRect();
      const reloadPosition = reloadRef.current.getBoundingClientRect();
      const registrationPosition =
        registrationRef.current.getBoundingClientRect();

      const distance =
        reloadPosition.left -
        (registrationPosition.left +
          registrationPosition.width +
          GAP_BETWEEN_FIELDS);
      if (distance <= mcdPosition.width) {
        setMarginLeft(reloadPosition.left - registrationPosition.left);
      } else {
        setMarginLeft(0);
      }
    }
  }

  useEffect(() => {
    repositionMCD();

    const handleResize = () => {
      repositionMCD();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    repositionMCD();
  }, [registration]);

  return (
    <div className={classNames(containerClassNames, themeColor)} {...others}>
      <div className={smallContainerClassNames}>
        <div className="flex gap-4 items-center">
          <div className={flightNumberClassNames}>{flightNumber}</div>
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
            []
          ) &&
            isCheckedIn && <PulseNotificationIcon />}
        </div>
        <div className={routeClassNames}>
          <div className={routeAirportClassNames}>{departureAirport}</div>
          <div className="px-8">
            <Icon variant="arrow" className="fill-white" />
          </div>
          <div className={routeAirportClassNames}>{arrivalAirport}</div>
        </div>
        <div ref={registrationRef} className={textClassNames}>{`${
          type || EMPTY_STATE
        }/${registration || EMPTY_STATE}`}</div>
        {showMCD && (
          <div
            ref={mcdRef}
            style={{
              marginLeft: `${marginLeft}px`,
            }}
            className={textClassNames}>
            {`MCD ${MCD || EMPTY_STATE}`}
          </div>
        )}
      </div>
      <div className={smallContainerClassNames}>
        <div className="flex gap-8">
          <div className={textClassNames}>{time1.value}</div>
          {time1.extraDetails && (
            <div className={extraDetailsClassNames}>{time1.extraDetails}</div>
          )}
        </div>
        <div className={timeClassNames}>{time2}</div>
      </div>
    </div>
  );
};

export default FlightDetailsHeaderSticky;
