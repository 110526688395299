import { useState, useEffect } from 'react';
import { AUTOMATIC, DARK, LIGHT } from '../constants';
import { getIsDayTime, getSettingsData } from '../helpers';

export const getAutomaticTheme = () => {
  const isDayTime = getIsDayTime();
  return isDayTime ? LIGHT : DARK;
};

const useDarkMode = (mode) => {
  const cachedTheme = getSettingsData()?.theme || AUTOMATIC;
  const colorTheme =
    cachedTheme === AUTOMATIC ? getAutomaticTheme() : cachedTheme;
  const [theme, setTheme] = useState(cachedTheme || mode);

  useEffect(() => {
    const root = window.document.documentElement.classList;
    root.remove(root[0]);
    root.add(theme === AUTOMATIC ? getAutomaticTheme() : theme);
    return () => {
      root.remove(root[0]);
      root.add(
        (getSettingsData()?.theme || AUTOMATIC) === AUTOMATIC
          ? getAutomaticTheme()
          : getSettingsData()?.theme
      );
    };
  }, [theme, colorTheme]);

  return [setTheme];
};

export default useDarkMode;
