import { EMPTY_STATE } from './constants';
import { IFlightNumber } from './generated/graphql';

export const calculateFlightNumber = (
  isUTC: boolean,
  flightNumberObject: IFlightNumber
) => {
  if (isUTC) {
    return flightNumberObject?.utc ?? EMPTY_STATE;
  } else {
    return flightNumberObject?.local ?? EMPTY_STATE;
  }
};
