import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CheckInUserType, Role } from '../../utils/generated/graphql';

interface CheckedInUser {
  userId: string;
  phone: string;
  technicalRole: string;
  userType: CheckInUserType;
  __typename: string;
}

export interface CheckedInResponsableRole {
  canChat: boolean;
  canCall: boolean;
  name: string;
  roleGroup: string;
  checkedInUsers: CheckedInUser[];
  technicalRole: string;
  __typename?: string;
}

interface CheckedInFlightItem {
  myRole: Role;
  roles: CheckedInResponsableRole[];
}

interface CheckedInFlight {
  [key: string]: CheckedInFlightItem;
}

export const checkinReducer = createSlice({
  name: 'checkin',
  initialState: {
    users: {} as CheckedInFlight,
    shouldFetchCheckedinFlights: false,
    checkedoutHighlander: {
      isCheckedOut: false,
      checkedoutFlight: null,
      checkedoutRole: null,
      checkedoutFlightNumber: null,
    },
  },
  reducers: {
    setCheckedinUsers: (state, action) => {
      state.users = action.payload.reduce(
        (checkedinUsers, flightCheckedInRole) => {
          const { flightId, roles, myRole } = flightCheckedInRole;
          checkedinUsers[flightId] = { roles, myRole };
          return checkedinUsers;
        },
        {}
      );
    },
    addCheckedinUser: (state, action) => {
      state.users = { ...state.users, ...action.payload };
    },
    updateCheckedinUser: (state, action) => {
      const { flightId, newUsers } = action.payload;
      const roleIndex = state.users[flightId]?.roles?.findIndex(
        (role) => role.roleGroup === newUsers.roleGroup
      );
      if (state.users[flightId] && roleIndex !== -1) {
        state.users[flightId].roles[roleIndex].checkedInUsers =
          newUsers.checkedInUsers;
      }
    },
    deleteCheckinUser: (state, action) => {
      delete state.users[action.payload];
    },
    setShouldFetchCheckedinFlights: (state, action) => {
      state.shouldFetchCheckedinFlights = action.payload;
    },
    setCheckedoutHighlander: (state, action) => {
      state.checkedoutHighlander = action.payload;
    },
  },
});

export const {
  setCheckedinUsers,
  addCheckedinUser,
  updateCheckedinUser,
  deleteCheckinUser,
  setShouldFetchCheckedinFlights,
  setCheckedoutHighlander,
} = checkinReducer.actions;

export const selectCheckedInUsers = (state: RootState) =>
  state.checkinReducer.users;
export const selectShouldFetchCheckedinFlights = (state: RootState) =>
  state.checkinReducer.shouldFetchCheckedinFlights;
export const selectCheckedoutHighlander = (state: RootState) =>
  state.checkinReducer.checkedoutHighlander;

export default checkinReducer.reducer;
