import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveChatFlightNumber,
  selectActiveChatScreen,
  setActiveFlightIdScreen,
  setActiveFlightNumberScreen,
} from '../../redux/reducers/newsfeedReducer';
import AllChats from './AllChats/AllChats';
import FlightChatContainer from './FlightChat/FlightChatContainer';

interface IChatsContainer {
  handleCloseChat: () => void;
}

const ChatsContainer = ({ handleCloseChat }: IChatsContainer) => {
  const activeChatScreen = useSelector(selectActiveChatScreen);
  const activeChatScreenFlightNumber = useSelector(
    selectActiveChatFlightNumber
  );
  const dispatch = useDispatch();

  const handleChatSelect = (
    flightId: string | null,
    flightNumber: string | null
  ) => {
    dispatch(setActiveFlightIdScreen(flightId));
    dispatch(setActiveFlightNumberScreen(flightNumber));
  };

  return (
    <>
      {activeChatScreen ? (
        <FlightChatContainer
          handleCloseChat={handleCloseChat}
          handleBack={() => handleChatSelect(null, null)}
          flightId={activeChatScreen}
          flightNumber={activeChatScreenFlightNumber}
        />
      ) : (
        <AllChats
          handleCloseChat={handleCloseChat}
          onChatSelect={handleChatSelect}
        />
      )}
    </>
  );
};

export default ChatsContainer;
